<template>
  <div
    ref="polyList"
    v-visible="visible"
    class="polygon-list"
    @mouseleave="$emit('request-close')"
  >
    <div
      v-if="editable"
      class="controls"
    >
      <el-button-group>
        <el-button
          :disabled="selectedIds.length === 0"
          size="mini"
          type="primary"
          @click="$emit('remove', selectedIds)"
        >
          Delete Selected
        </el-button>
      </el-button-group>
    </div>
    <div>
      <div>
        <el-table
          :data="polygons"
          :header-row-class-name="() => 'transparent'"
          :max-height="tableMaxHeight"
          :row-class-name="() => 'transparent'"
          class="transparent"
          size="mini"
          @cell-mouse-enter="mouseEnter"
          @cell-mouse-leave="mouseLeave"
          @selection-change="selectionChange"
        >
          <el-table-column
            v-if="editable"
            type="selection"
            width="55"
          />
          <el-table-column
            :formatter="areaFormatter"
            label="Area"
            prop="area"
          />
          <el-table-column>
            <template slot-scope="scope">
              <el-button-group>
                <el-button
                  icon="fa fa-fw fa-location"
                  size="mini"
                  @click="$emit('focus', scope.row.id)"
                />
                <el-button
                  v-if="editable"
                  icon="fa fa-fw fa-trash"
                  size="mini"
                  type="danger"
                  @click="$emit('remove', [scope.row.id])"
                />
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import area from '@turf/area';

export default {
  props: {
    editable: {
      type: Boolean,
      required: true,
    },
    features: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      selectedIds: [],
      tableMaxHeight: 100, // some arbitrary value
    };
  },

  computed: {
    polygons() {
      return this.features.map((feature, i) => ({
        id: feature.id,
        area: Math.abs(area(feature)),
        name: '',
      }));
    },
  },

  watch: {
    layers() {
      this.tableMaxHeight = this.$refs.polyList.clientHeight - 100;
    },

    visible(visible) {
      if (visible) {
        this.tableMaxHeight = this.$refs.polyList.clientHeight - 100;
      }
    },
  },

  methods: {
    areaFormatter(row) {
      return (
        Intl.NumberFormat(navigator.language, {
          maximumFractionDigits: 2,
        }).format(row.area) + ' m²'
      );
    },

    mouseEnter(row) {
      this.$emit('highlight', row.id, true);
    },

    mouseLeave(row) {
      this.$emit('highlight', row.id, false);
    },

    nameFormatter(row) {
      return row.name || 'N/A';
    },

    selectionChange(selection) {
      this.selectedIds = selection.map(sel => sel.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.controls {
  margin-bottom: 1em;
}

.polygon-list {
  border-radius: 2px;
  box-shadow: -2px 2px 6px 2px rgba(0, 0, 0, 0.2);
  height: 90%;
  padding: 20px;
  position: relative;
  width: 360px;
  z-index: 501;
  background: rgba(255, 255, 255, 0.95);
  position: absolute;
  right: 52px;
  top: 0.5em;
}
</style>

<style lang="scss">
.transparent {
  background: transparent !important;

  th {
    background: transparent !important;
  }
}
</style>
