import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import tinycolor from 'tinycolor2';
import kinks from '@turf/kinks';
import { createDebugLogger, debugCategories } from '@/helpers/debug';
import mapStyles, { FILL_OPACITY, FILL_COLOR_INACTIVE } from './map-styles';
import { CustomControlGroup, CustomControl } from './custom-controls';
import bbox from '@turf/bbox';

const debug = createDebugLogger(debugCategories.MAP);

const defaultMapOptions = {
  center: [-98, 38],
  dragRotate: false,
  hash: true,
  minZoom: 2,
  maxZoom: 19,
  zoom: 4.5,
};

const COLOR_SPIN_AMOUNT = 45;

export default class Map {
  constructor(elementId, options = {}) {
    const opts = {
      ...defaultMapOptions,
      ...options,
    };

    if (!opts.mapApiKey) {
      throw new Error('PolyMap: mapApiKey is required');
    }

    debug('ms since last destroy', Date.now() - Map.lastDestroyed);

    // Data and local settings
    this.markers = [];
    this.eventHandlers = {};
    this.darkMode = false;
    this.defaultLayer = opts.initialGeoJson
      ? {
        id: 'polygons',
        type: 'fill',
        source: {
          type: 'geojson',
          data: opts.initialGeoJson,
        },
        layout: {},
        paint: {
          'fill-color': FILL_COLOR_INACTIVE,
          'fill-opacity': FILL_OPACITY,
        },
      }
      : null;
    this.poiColors = ['#e15d26', '#42c3ff', '#70e02f', '#e664fa'];
    this.colorSpinAmount = 0;
    this.nextColorIndex = 0;
    this.plotRound = 0;

    this.init(elementId, opts);
    this.load = () =>
      new Promise(resolve => {
        this.map.on('load', () => resolve());
      });
  }

  clearMarkers() {
    this.markers.forEach(marker => marker.remove());
    this.markers = [];
  }

  clearPolygons() {
    this.draw
      .getAll()
      .features.map(f => f.id)
      .forEach(id => this.draw.delete(id));
    this.emitUpdate();
  }

  destroy() {
    return new Promise(resolve => {
      debug('Received request to destroy map');
      this.map && this.map.remove();
      resolve();
    });
  }

  emitUpdate() {
    this.eventHandlers.update(this.draw.getAll());
  }

  focusPolygons(ids, options = {}) {
    let totalBounds;

    for (const id of ids) {
      const feature = this.draw.get(id);
      const [west, north, east, south] = bbox(feature);
      const sw = [west, south];
      const ne = [east, north];

      if (typeof totalBounds === 'undefined') {
        totalBounds = new mapboxgl.LngLatBounds(sw, ne);
      } else {
        const bounds = new mapboxgl.LngLatBounds(sw, ne);
        totalBounds.extend(bounds);
      }
    }

    this.map.fitBounds(totalBounds, {
      ...options,
      padding: 100,
    });
  }

  focusPoints(points) {
    if (points.length === 1) {
      this.map.flyTo({
        center: points[0],
        zoom: 18,
      });
      return;
    }

    const bounds = new mapboxgl.LngLatBounds(points[0], points[1]);

    points.slice(2).forEach(pt => bounds.extend(pt));
    this.map.fitBounds(bounds, { padding: 50 });
  }

  getBounds(feature) {
    const [west, north, east, south] = bbox(feature);

    return [west, south, east, north];
  }

  highlightPolygon(id, highlight) {
    // FIXME: will fix some day.. ?
    // if (highlight) {
    //   this.draw.setFeatureProperty(id, 'polyFill', '#f00');
    //   this.draw.setFeatureProperty(id, 'polyOutline', '#f00');
    // } else {
    //   this.draw.setFeatureProperty(id, 'polyFill', '');
    //   this.draw.setFeatureProperty(id, 'polyOutline', '');
    // }
  }

  async init(elementId, opts) {
    mapboxgl.accessToken = opts.mapApiKey;

    this.map = new mapboxgl.Map({
      ...opts,
      ...(opts.initialGeoJson
        ? {
          bounds: this.getBounds(opts.initialGeoJson),
          fitBoundsOptions: { padding: 100 },
        }
        : {}),
      ...(opts.readOnly
        ? {
          hash: false,
        }
        : {}),
      container: elementId,
      style: 'mapbox://styles/mapbox/satellite-streets-v11',
      attributionControl: false,
    });

    // Force 100% width, not sure what's preventing this otherwise...
    this.map._canvas.style.width = '100%';

    this.map.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
      }),
      'top-left',
    );
    this.map.addControl(new mapboxgl.FullscreenControl(), 'top-left');
    this.map.addControl(new mapboxgl.ScaleControl(), 'bottom-right');

    this.draw = new MapboxDraw({
      userProperties: true,
      controls: {
        combine_features: false,
        line_string: false,
        uncombine_features: false,
        point: false,
        ...(opts.readOnly
          ? {
            polygon: false,
            trash: false,
          }
          : {}),
      },
      ...(opts.readOnly ? {} : { defaultMode: 'draw_polygon' }),
      styles: mapStyles,
    });
    this.map.addControl(this.draw, 'top-right');

    window.map = this.map;
    window.draw = this.draw;

    // Custom controls
    this.map.addControl(new CustomControlGroup());
    this.map.addControl(
      new CustomControl({
        className: 'fa fa-lg fa-list',
        title: 'Polygon List',
        events: {
          onmouseenter() {
            opts.vm.showPolyList = true;
          },
          onmouseleave() {
            opts.vm.timedClosePolyList();
          },
        },
        // postRender(el) {
        //   const badge = document.createElement('sup');

        //   badge.className =
        //     'el-badge__content el-badge__content--undefined is-fixed';
        //   badge.textContent = '?';
        //   badge.style.position = 'absolute';
        //   badge.style.top = '0';
        //   badge.style.pointerEvents = 'none';
        //   badge.style.fontFamily = 'Roboto';
        //   el.style.position = 'relative';
        //   el.appendChild(badge);
        // },
      }),
    );
    this.map.addControl(
      new CustomControl({
        className: 'fa fa-lg fa-moon',
        title: 'Dark Mode',
        events: {
          onclick({ target }) {
            // Yeah, hacky, but whatever
            target.className = /moon/.test(target.className)
              ? target.className.replace('moon', 'sun')
              : target.className.replace('sun', 'moon');
            opts.vm.handleDarkMode();
          },
        },
      }),
    );
    this.map.addControl(
      new CustomControl({
        className: 'fa fa-lg fa-question',
        title: 'Help',
        events: {
          onclick() {
            opts.vm.showHelp();
          },
        },
      }),
    );

    // Set up event handlers
    this.map.on('draw.create', ({ features }) => {
      // Necessary to prevent self-intersecting
      // polygons on double-click to close
      if (kinks(features[0]).features.length > 0) {
        this.eventHandlers.removeSelfIntersection(features[0].id);
      }

      this.emitUpdate();

      // Maintain draw mode
      setTimeout(() => {
        this.draw.changeMode('draw_polygon');
      }, 100);
    });
    this.map.on('draw.delete', () => {
      this.emitUpdate();
    });
    this.map.on('draw.update', () => {
      this.emitUpdate();
    });
    this.map.on('style.load', event => {
      if (opts.initialGeoJson) {
        this.map.addLayer(this.defaultLayer);
      }
    });
  }

  get mode() {
    return this.draw.getMode();
  }

  on(eventName, callback) {
    this.eventHandlers[eventName] = callback;
  }

  plotPoint(latLng, options = {}) {
    const marker = new mapboxgl.Marker({
      color: '#e15d26',
    })
      .setLngLat(latLng)
      .addTo(this.map);
    const popup = new mapboxgl.Popup().setText(options.label);

    marker.setPopup(popup);
    marker.togglePopup();
    this.markers.push(marker);
  }

  // FIXME
  plotPois(pois, options = {}) {
    if (options.clear) this.clearMarkers();
    const color = tinycolor(this.poiColors[this.nextColorIndex++]).spin(
      this.plotRound * COLOR_SPIN_AMOUNT,
    );

    if (this.nextColorIndex >= this.poiColors.length) {
      this.nextColorIndex = 0;
      ++this.plotRound;
    }

    for (const poi of pois) {
      const marker = new mapboxgl.Marker({
        color: color.toHexString(),
      })
        .setLngLat(poi.center)
        .addTo(this.map);
      const popup = new mapboxgl.Popup().setHTML(
        `<b>${poi.label}</b><br>${poi.address}`,
      );

      marker.setPopup(popup);
      this.markers.push(marker);
    }

    this.focusPoints(this.markers.map(marker => marker.getLngLat()));
  }

  removePolygon(id) {
    this.draw.delete(id);
    this.emitUpdate();
  }

  setDarkMode(enable) {
    // if (this.defaultLayer && this.map.getLayer('polygons')) {
    //   this.map.removeLayer(this.defaultLayer.id);
    // }

    if (enable) {
      if (this.darkMode) return;
      this.map.setStyle('mapbox://styles/mapbox/dark-v10');
    } else {
      if (!this.darkMode) return;
      this.map.setStyle('mapbox://styles/mapbox/satellite-streets-v11');
    }

    // this.defaultLayer && this.map.addLayer(this.defaultLayer);
    this.darkMode = enable;
  }

  setMode(mode) {
    this.draw.changeMode(mode);
  }
}
