<template>
  <div
    :class="isOrder ? 'order-page' : ''"
    class="poly-map"
  >
    <video-tour
      :videos="videos"
      :visible.sync="showVideoTour"
    />
    <div id="map" />
    <polygon-list
      :editable="editable"
      :features="features"
      :visible="showPolyList"
      @focus="focusOnPolygon"
      @highlight="highlightPolygon"
      @mouseenter.native="polyListMouseEnter"
      @remove="removePolygons"
      @request-close="timedClosePolyList"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import area from '@turf/area';
import Map from './map-class';
import PolygonList from './PolygonList.vue';
import VideoTour from '../VideoTour.vue';

export default {
  components: { PolygonList, VideoTour },
  props: {
    editable: { type: Boolean, default: false },
    isOrder: { type: Boolean, default: false },
    geojson: { type: Object, default: null },
  },

  data() {
    return {
      darkMode: false,
      features: [],
      showPolyList: false,
      showVideoTour: false,
      totalArea: 0,
      videos: Object.freeze([
        {
          text:
            'To draw polygons, just click to define points, and double-click ' +
            'on the last point to close the shape. Press ESC to exit edit ' +
            'mode.',
          title: 'Basic Polygon Drawing',
          url:
            process.env.VUE_APP_PUBLIC_BUCKET_PREFIX +
            '/video-tour/basic-drawing.mp4',
        },
        {
          text:
            'A polygon can be deleted by clicking it to select it, then ' +
            'either pressing delete/backspace on the keyboard or clicking the ' +
            'delete button in the map tools.',
          title: 'Deleting Polygons',
          url:
            process.env.VUE_APP_PUBLIC_BUCKET_PREFIX +
            '/video-tour/delete-poly.mp4',
        },
        {
          text:
            "If you need to edit polygons after they've been drawn, double-" +
            "click one of them and you'll see midpoints appear between the " +
            'existing points. You can drag any of these points to refine ' +
            'the polygon.',
          title: 'Polygon Editing',
          url:
            process.env.VUE_APP_PUBLIC_BUCKET_PREFIX +
            '/video-tour/poly-editing.mp4',
        },
        {
          text:
            'You can easily navigate to different polygons by opening ' +
            'the polygon list, and clicking the target button. The polygon ' +
            'list will also let you delete one or more shapes at once.',
          title: 'Locating Polygons',
          url:
            process.env.VUE_APP_PUBLIC_BUCKET_PREFIX +
            '/video-tour/locate-poly.mp4',
        },
        {
          text:
            'Select multiple polygons by either shift-clicking them, or ' +
            'you can shift-drag a box around them.',
          title: 'Group Select',
          url:
            process.env.VUE_APP_PUBLIC_BUCKET_PREFIX +
            '/video-tour/group-select.mp4',
        },
        {
          text:
            'Sometimes polygons can be difficult to spot when zoomed ' +
            'out. In addition to using the locator in the polygon list, you ' +
            'can also enable dark mode to get a better look at where shapes ' +
            'are on the map.',
          title: 'Dark Mode',
          url:
            process.env.VUE_APP_PUBLIC_BUCKET_PREFIX +
            '/video-tour/dark-mode.mp4',
        },
        {
          text:
            'Use our Plot Points feature to quickly drop several pins to ' +
            'locate business locations. Only the search results shown are ' +
            'used to plot points.',
          title: 'Plotting Multiple POIs',
          url:
            process.env.VUE_APP_PUBLIC_BUCKET_PREFIX +
            '/video-tour/plot-points.mp4',
        },
      ]),
      warnedArea: false,
    };
  },

  computed: {
    ...mapGetters('settings', ['maximumArea']),

    darkModeBtnClass() {
      return {
        button: true,
        enabled: this.darkMode,
      };
    },

    mapControlsStyle() {
      return {
        top: this.editable ? '160px' : '12px',
      };
    },
  },

  watch: {
    totalArea(value) {
      // Handle area warnings
      if (value > this.maximumArea) {
        this.$notify.error({
          title: 'Maximum Area Exceeded',
          message: `Your polygons have exceeded the maximum area of ${
            this.maximumArea
          } m². Please delete some in order to proceed.`,
        });
      } else if (value / this.maximumArea >= 0.8 && !this.warnedArea) {
        this.$notify.warning({
          title: 'Approaching Maximum Area',
          message: `You're approaching the maximum geoframing area allowed (${
            this.maximumArea
          } m²).`,
        });

        this.warnedArea = true;
      } else {
        this.warnedArea = false;
      }
    },
  },

  async mounted() {
    await this.$nextTick();

    this.map = new Map('map', {
      mapApiKey: process.env.VUE_APP_MAPBOX_TOKEN,
      readOnly: !this.editable,
      vm: this,
      initialGeoJson: this.geojson, // used only for initial position & zoom
    });
    await this.map.load();

    // Listen for changes
    this.map.on('update', featureCollection => {
      this.features = featureCollection.features;
      this.totalArea = Math.abs(area(featureCollection));

      const coordinates = featureCollection.features.map(
        feature => feature.geometry.coordinates,
      );

      this.$emit('update', {
        geojson: {
          type: 'Feature',
          geometry: {
            type: 'MultiPolygon',
            coordinates,
          },
          properties: {},
        },
        totalArea: this.totalArea,
      });
    });

    // Warn that self-intersected polygon was removed
    this.map.on('removeSelfIntersection', (id) => {
      this.$notify.error({
        title: 'Self Intersection is not allowed',
        message: 'Your polygon was self-intersecting and has been removed.',
      });

      this.removePolygons([id]);
    });
  },

  async beforeDestroy() {
    await this.map.destroy();
    this.map = null;
  },

  methods: {
    focusOnPolygon(id) {
      this.map.focusPolygons([id]);
    },

    handleDarkMode() {
      this.darkMode = !this.darkMode;
      this.map.setDarkMode(this.darkMode);
    },

    highlightPolygon(id, highlight) {
      this.map.highlightPolygon(id, highlight);
    },

    pinpoint(feature) {
      this.map.clearMarkers();

      if (!feature.bbox) {
        this.map.plotPoint(feature.center, {
          label: feature.address,
        });
        this.map.focusPoints([feature.center]);
      } else {
        // focus on a general area
        this.map.focusPoints([feature.bbox.slice(0, 2), feature.bbox.slice(2)]);
      }
    },

    polyListMouseEnter() {
      clearTimeout(this.timer);
    },

    plotPois(pois, clear) {
      this.map.plotPois(pois, { clear });
    },

    removePolygons(ids) {
      ids.forEach(id => this.map.removePolygon(id));
    },

    reset() {
      if (this.map) {
        this.map.clearMarkers();
        this.map.clearPolygons();
        this.map.setMode('draw_polygon');
      }
    },

    showHelp() {
      this.showVideoTour = true;
    },

    timedClosePolyList() {
      this.timer = setTimeout(() => {
        this.showPolyList = false;
      }, 250);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~$custom';

.poly-map {
  overflow: hidden;
  position: relative;
  height: calc(100vh - 290px);
  &.order-page {
    height: 55vh;
  }
  width: 100%;
}

#map {
  width: 100%;
  height: 100%;
  .mapboxgl-canvas {
    width: 100% !important;
  }
}

.map-controls {
  position: absolute;
  right: 12px;
  z-index: 500;

  .button {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    cursor: pointer;
    font-size: 1.4em;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px;

    &:hover {
      background-color: #f4f4f4;
    }

    &.enabled {
      background-color: $--color-primary;

      .poly-icon {
        color: #fff;
      }
    }
  }
}

:deep( .el-badge__content.is-fixed) {
  right: 40px;
}

.poly-icon {
  color: #555;
}
</style>
