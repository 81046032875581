<template>
  <div>
    <el-dialog
      class="main-dialog"
      :before-close="closeTour"
      :visible="visible"
      width="75%"
    >
      <div class="content">
        <div class="text">
          <h1>{{ currentVideo.title }}</h1>
          <p>{{ currentVideo.text }}</p>
        </div>
        <div
          v-loading="loading"
          class="video-container"
        >
          <video
            ref="video"
            autoplay
            muted
            :src="currentVideo.url"
            @canplaythrough="videoReady"
            @ended="videoEnded"
          />
          <transition name="slow-fade">
            <div
              v-show="donePlaying"
              class="play-button-overlay"
            >
              <i
                class="play-button fa fa-5x fa-play-circle"
                @click="replay"
              />
            </div>
          </transition>
        </div>
      </div>

      <span slot="footer">
        <el-button
          :disabled="currentVideoIndex <= 0"
          @click="advance(-1)"
        >
          Previous
        </el-button>
        <el-button
          v-show="!onLastVideo"
          type="primary"
          @click="advance(1)"
        >
          Next
        </el-button>
        <el-button
          v-show="onLastVideo"
          type="primary"
          @click="closeTour"
        >
          Got It!
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'VideoTour',
  props: {
    videos: {
      type: Array,
      required: true,
    },

    visible: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      currentVideoIndex: 0,
      donePlaying: false,
      loading: true,
      posterUrl:
        process.env.VUE_APP_PUBLIC_BUCKET_PREFIX +
        '/video-tour/video-loading-poster.png',
    };
  },

  computed: {
    currentVideo() {
      return this.videos[this.currentVideoIndex];
    },

    onLastVideo() {
      return this.currentVideoIndex >= this.videos.length - 1;
    },
  },

  watch: {
    currentVideoIndex() {
      this.donePlaying = false;
    },

    visible(visible) {
      if (visible) {
        this.reset();
      }
    },
  },

  methods: {
    advance(direction) {
      this.currentVideoIndex += direction;
      this.loading = true;
    },

    closeTour() {
      this.$emit('update:visible', false);
    },

    replay() {
      this.donePlaying = false;
      this.$refs.video.play();
    },

    reset() {
      this.currentVideoIndex = 0;
      this.donePlaying = false;
      this.loading = false;
    },

    videoEnded() {
      this.donePlaying = true;
    },

    videoReady() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
}

.text {
  flex: 1;
  padding-right: 1rem;

  h1 {
    font-size: 1.5rem;
    margin-top: 0;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.video-container {
  height: 280px;
  position: relative;
  width: 500px;

  video {
    height: 280px;
    width: 100%;
  }
}

.play-button-overlay {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.play-button {
  color: #e15d26;
  cursor: pointer;
}

.slow-fade-enter-active {
  transition: opacity 1s ease;
}

.slow-fade-leave-active {
  transition: opacity 0.3s ease;
}

.slow-fade-enter,
.slow-fade-leave-to {
  opacity: 0;
}

:deep( .main-dialog > div) {
  max-width: 860px;
}
</style>
