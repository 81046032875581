/**
 * CustomControlGroup class is a group/container for our own custom controls
 */
export class CustomControlGroup {
  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl custom';

    return this.container;
  }

  onRemove() {
    this.container && this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

/**
 * Custom Mapbox control
 */
export class CustomControl {
  /**
   *
   * @param {object} options
   * @param {string} options.className CSS classes to apply to the control
   * @param {string} options.title Text to show when hovering over
   * @param {object} events Native events to attach to the control (onclick, etc)
   * @param {function} postRender Optional function to call after the control has been rendered; passes the button element as an argument
   */
  constructor({ className, title, events, postRender }) {
    this.className = className;
    this.title = title;
    this.events = events;
    this.postRender = postRender;
  }

  onAdd() {
    const button = document.createElement('button');
    button.className = `${this.className} mapbox-gl-draw_ctrl-draw-btn`;
    button.title = this.title;
    Object.assign(button, this.events);

    const group = document.querySelector('.mapboxgl-ctrl-group.custom');
    group.appendChild(button);

    this.postRender && this.postRender(button);

    return group;
  }

  onRemove() {
    this.container && this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}
